import lsrestclient, {
  TLsRestClientCall,
} from '@shared/legacy_compat/lsrestclient/index';
import {
  TPaginationParameter,
  TPaginationResponse,
} from '@shared/types/pagination';
import { TGroupTag } from '@shared/types/group_tag';
import { withPaginationParameters } from '@shared/components/LsPagination/functions';
// import { extract_data_for_mui_pagination_view } from '@shared/functions/extract_data_for_mui_pagination_view';
import { extract_data_for_mui_pagination_view } from '@shared/functions/extract_data_for_mui_pagination_view';
import { TDictionary } from '@shared/types';
import { getLsConfig } from '@shared/legacy_compat/nextjs/lsconfig/getLsConfig';
import _ from 'lodash';

const debug_log = false;

const { runtimeConfig } = getLsConfig();

const render_product_class = _.get(
  runtimeConfig,
  'NEXT_PUBLIC_RENDER_PRODUCT_CLASS',
);
const render_product_version = _.get(
  runtimeConfig,
  'NEXT_PUBLIC_RENDER_PRODUCT_VERSION',
);
const org_id = _.get(runtimeConfig, 'NEXT_PUBLIC_ORG_ID');

type TApiRenderProductsParameter = TPaginationParameter & {};
export const apiRenderProductsRead = async ({
  ...parameter
}: TLsRestClientCall<TApiRenderProductsParameter>): Promise<
  TPaginationResponse<TGroupTag> | undefined
> => {
  const client = lsrestclient.client();
  return await client(
    withPaginationParameters(
      {
        method: 'put',
        url: '/render/rendered_products',
        params: {
          org_id,
          version: render_product_version,
          render_class: render_product_class,
          view: 'FULL',
        },
        body: {
          filter: {},
        },
      },
      parameter,
    ),
  );
};

export const renderedProductsPaginationView = async ({
  ...parameter
}: TLsRestClientCall<TApiRenderProductsParameter>) => {
  const res = await apiRenderProductsRead({
    ...parameter,
  });
  if (debug_log)
    console.log(
      'rendered_products_pagination_view options',
      parameter.config?.options,
    );
  if (debug_log) console.log('rendered_products_pagination_view res', res);
  return extract_data_for_mui_pagination_view(res);
};

type TApiRenderProductsSearch = {
  query?: TDictionary<string>;
};
export const apiRenderProductsSearch = async ({
  query = {},
  ...parameter
}: TLsRestClientCall<
  TApiRenderProductsParameter & TApiRenderProductsSearch
>) => {
  const client = lsrestclient.client();
  return await client(
    withPaginationParameters(
      {
        method: 'put',
        url: '/render/rendered_products/search',
        params: {
          org_id,
          version: render_product_version,
          render_class: render_product_class,
          view: 'FULL',
        },
        body: {
          query,
          filter: {},
        },
      },
      parameter,
    ),
  );
};

type TApiRenderProductsReadGiftFinder2 = TPaginationParameter &
  TApiRenderProductsParameter & {
    group_tags: string[];
    price_start: number;
    price_end: number;
    selected_group_tags: string[];
  };
export const apiRenderProductsReadGiftFinder2 = async ({
  skip = 0,
  limit = 24,
  group_tags,
  price_start,
  price_end,
  selected_group_tags,
  ...parameter
}: TLsRestClientCall<TApiRenderProductsReadGiftFinder2>) => {
  const client = lsrestclient.client();
  return await client(
    withPaginationParameters(
      {
        method: 'put',
        url: '/render/rendered_products/gift_finder/2',
        params: {},
        body: {
          skip,
          limit,
          org_id,
          version: render_product_version,
          render_class: render_product_class,
          group_tags,
          price_start,
          price_end,
          selected_group_tags,
          view: 'FULL',
          filter: {},
        },
      },
      parameter,
    ),
  );
};
