const { default: getConfig } = require('next/config');
const { getGlobalLsConfig } = require('./getGlobalLsConfig');

// import getConfig from 'next/config';

const _ = require('lodash');

function getLsConfigClient() {
	const elem = document.getElementById('__LSOFT_CONFIG__');
	if (_.isNil(elem)) {
		return {};
	}
	return JSON.parse(decodeURIComponent(elem.innerText));
}

function getLsConfigServer() {
	const config = getGlobalLsConfig();
	const { publicRuntimeConfig } = getConfig();
	const ret = {
		runtimeConfig: Object.assign({}, publicRuntimeConfig, {
			NODE_ENV: process.env.NODE_ENV,
			APP_VERSION: process.env.npm_package_version,
		}),
		...config.server,
	};
	return ret;
}

function getLsConfig() {
	return typeof window === 'undefined'
		? getLsConfigServer()
		: getLsConfigClient();
}

module.exports = {
	getLsConfig,
};
