/*
This code is a hybrid ( server and client side ) library for the usage with Next.js, Be careful.
We have to import dynamically and take care which code to use where.
*/

const isServer = typeof window === 'undefined';

// very tricky import only on server side because next/headers is not allowed on client side
const { cookies: index } = isServer
  ? require('next/headers')
  : {
      cookies: null,
    };

const getCookieServerOnly = (cookieName: string): string | undefined => {
  const cookieStore = index();
  // noinspection TypeScriptValidateJSTypes
  const cookie = cookieStore.get(cookieName) || { value: undefined };
  return cookie.value;
};

const getCookieClientOnly = (cookieName: string): string | undefined => {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
};

export const getCookie = (
  cookieName: string,
  default_value?: string,
): string | undefined => {
  return (
    (isServer
      ? getCookieServerOnly(cookieName)
      : getCookieClientOnly(cookieName)) || default_value
  );
};
