/*
 This code needs to be es5 because it will be imported into next.config.js
 */

const _ = require('lodash');
const { getLsConfig } = require('./getLsConfig');

const LsSentryConfig = (config, exports = null) => {
	// noinspection JSValidateTypes
	const useLsConfig = _.isNil(exports);
	const runtimeConfig = useLsConfig
		? _.get(getLsConfig(), 'runtimeConfig')
		: _.get(exports, 'publicRuntimeConfig');

	const {
		NODE_ENV,
		APP_VERSION,
		NEXT_PUBLIC_SENTRY_DEBUG,
		NEXT_PUBLIC_SENTRY_SILENT,
		NEXT_PUBLIC_SENTRY_ENABLED,
		NEXT_PUBLIC_SENTRY_DSN,
		NEXT_PUBLIC_SENTRY_ENV,
		NEXT_PUBLIC_SENTRY_DRYRUN,
		NEXT_PUBLIC_SENTRY_RELEASE,
		NEXT_PUBLIC_SENTRY_PROJECT,
		NEXT_PUBLIC_SENTRY_ORG,
		NEXT_PUBLIC_SENTRY_AUTH_TOKEN,
	} = runtimeConfig;
	const debug = NEXT_PUBLIC_SENTRY_DEBUG === 'true';
	const silent = NEXT_PUBLIC_SENTRY_SILENT === 'true';
	const dryRun = NEXT_PUBLIC_SENTRY_DRYRUN === 'true';
	const enabled =
		NEXT_PUBLIC_SENTRY_ENABLED === 'true' || NODE_ENV === 'production';

	const sentryOptions = {
		authToken: NEXT_PUBLIC_SENTRY_AUTH_TOKEN,
		dsn: NEXT_PUBLIC_SENTRY_DSN,
		environment: NEXT_PUBLIC_SENTRY_ENV,
		org: NEXT_PUBLIC_SENTRY_ORG || 'electronic-shop-sarl',
		project: NEXT_PUBLIC_SENTRY_PROJECT,
		release: NEXT_PUBLIC_SENTRY_RELEASE || APP_VERSION,
		silent: silent,
		debug: debug,
		dryRun: dryRun,
		enabled: enabled,
	};

	if (debug) {
		console.log('Using sentry options:');
		Object.entries(sentryOptions).map(([k, v]) =>
			console.log(`sentryOptions.${k}: '${v}'`),
		);
	}
	return _.merge({}, config, sentryOptions);
};

module.exports = {
	LsSentryConfig,
};
