import _ from 'lodash';
import { check_now_in_timeframe } from './promotions';
import { getLsConfig } from '../../legacy_compat/nextjs/lsconfig/getLsConfig';

const { runtimeConfig } = getLsConfig();

export const product_internet_stock_amount_threshold = () =>
	parseInt(_.get(runtimeConfig, 'NEXT_PUBLIC_INTERNET_STOCK_AMOUNT', '1'));

export const product_check_stock_available = (data) => {
	const { stock_available, ignore_internet_stock_amount } = data;
	const internet_stock_amount_greater_than_threshold =
		stock_available >= product_internet_stock_amount_threshold();
	const internet_stock_amount_not_zero_and_ignore_threshold =
		stock_available !== 0 && ignore_internet_stock_amount;
	return (
		internet_stock_amount_greater_than_threshold ||
		internet_stock_amount_not_zero_and_ignore_threshold
	);
};

export const product_stock_info_variant = (data) => {
	const { presale, sellable, delivery_date, delivery_time_working_days } = data;
	if (presale && sellable) {
		return 'presale';
	}
	if (!sellable) {
		return 'coming_soon';
	}
	if (!_.isNil(delivery_date)) {
		const delivery_date_passed = Date.parse(delivery_date) - new Date() < 0;
		if (!delivery_date_passed && delivery_time_working_days !== 1) {
			return 'delivery_date';
		}
	}
	if (product_check_stock_available(data)) {
		if (delivery_time_working_days === 1) {
			return 'on_stock';
		}
		if (delivery_time_working_days >= 2 && delivery_time_working_days <= 5) {
			return 'short_term';
		}
		if (delivery_time_working_days >= 6 && delivery_time_working_days <= 10) {
			return 'mid_term';
		}
	}
	return 'request';
};

export const product_check_date_passed = (delivery_date) => {
	if (_.isNil(delivery_date)) return false;
	const now = new Date().toISOString();
	const now_parsed = Date.parse(now);
	const delivery_date_parsed = Date.parse(delivery_date);
	return delivery_date_parsed - now_parsed > 0;
};

const product_check_discount_in_timeframe = (data) => {
	const { discount_start_date, discount_end_date } = data;
	return check_now_in_timeframe(discount_start_date, discount_end_date);
};

export const product_check_discount_available = (data) => {
	// Extract data
	const {
		discount_start_date,
		discount_end_date,
		discount_only_stock,
		netto_dp,
		brutto_sp,
		brutto_dp,
	} = data;
	// If any of those dates is null, return false
	if (
		_.includes(
			[discount_start_date, discount_end_date, netto_dp, brutto_dp],
			null,
		)
	)
		return false;
	// If discount_only_stock && stock is not available
	if (discount_only_stock && !product_check_stock_available(data)) return false;
	// If in discount timeframe, ...
	if (product_check_discount_in_timeframe(data)) {
		// ... and discount price lower normal price, return true
		if (brutto_dp < brutto_sp) {
			return true;
		}
	}
	// Default false
	return false;
};
