import _ from 'lodash';
import lsrestclient, {
  LsApiError,
  TLsRestClientCall,
} from '@shared/legacy_compat/lsrestclient/index';
import {
  TAccount,
  TAccountBase,
  TAccountList,
  TOrganization,
  TOrganizationBase,
  TRole,
  TRoleBase,
  TScope,
  TScopeBase,
} from '@shared/types/am';
import { TDictionary } from '@shared/types';
import { getLsConfig } from '@shared/nextjs/lsconfig/getLsConfig';
import { verifySignedStringDict } from '@shared/functions/signature';
import {
  TPaginationParameter,
  TPaginationResponse,
} from '@shared/types/pagination';

type TApiAmAccountParameter = {
  account_id: string;
};

export const apiAmAccountRead = async ({
  account_id,
}: TLsRestClientCall<TApiAmAccountParameter>): Promise<
  TAccount | undefined
> => {
  const client = lsrestclient.client();

  const params = {
    account_id,
  };
  return await client({
    method: 'get',
    url: '/am/account/{account_id}',
    params: params,
  });
};

type TApiAmAccountsReadParameter = TPaginationParameter & {
  org_id?: string;
};

// export const apiAmAccountsRead = async ({
//   ...params
// }: TLsRestClientCall<TApiAmAccountsReadParameter>): Promise<
//   TPaginationResponse<TAccount | TAccountList | TAccountBase>
// > => {
//   // }: TApiAmAccountParameter): Promise<TApiAccountResponse | undefined> => {
//   const client = lsrestclient.client();
//   return await client(
//     withPaginationParameters(
//       {
//         method: 'put',
//         url: '/am/accounts',
//       },
//       params,
//     ),
//   );
// };

export const apiAmAccountNameRead = async ({
  account_id,
}: TLsRestClientCall<TApiAmAccountParameter>): Promise<string | undefined> => {
  const client = lsrestclient.client();

  const params = {
    account_id,
  };
  const ret = await client({
    method: 'get',
    url: '/am/account/{account_id}',
    params: params,
  });
  return _.get(ret, 'NAME');
};

type TApiAccountUpdateParameter = {
  account_id: string;
  account: TAccount;
  merge_spec: TDictionary<any>;
};
export const apiAmAccountUpdate = async ({
  account_id,
  account,
  merge_spec = {},
}: TLsRestClientCall<TApiAccountUpdateParameter>): Promise<
  TAccount | undefined
> => {
  const client = lsrestclient.client();
  const params = {
    account_id,
  };

  return await client({
    method: 'put',
    url: '/am/account/{account_id}',
    params,
    body: { account, merge_spec },
  });
};

type TApiAmAccountDeleteParameter = {
  account_id: string;
};
export const apiAmAccountDelete = async ({
  account_id,
}: TLsRestClientCall<TApiAmAccountDeleteParameter>): Promise<null> => {
  const client = lsrestclient.client();
  const params = {
    account_id,
  };
  return await client({
    method: 'delete',
    url: '/am/account/{account_id}',
    params,
  });
};

type TApiAmAccountCreateParameter = {
  account: TAccount;
};
export const apiAmAccountCreate = async ({
  account,
}: TLsRestClientCall<TApiAmAccountCreateParameter>): Promise<TAccount> => {
  const client = lsrestclient.client();

  return await client({
    method: 'post',
    url: '/am/account',
    body: account,
  });
};

type TApiAmAccountChangePasswordParameter = {
  account_id: string;
  password_change: {
    old_password: string;
    new_password: string;
  };
};
export const apiAmAccountChangePassword = async ({
  account_id,
  password_change,
}: TLsRestClientCall<TApiAmAccountChangePasswordParameter>): Promise<TAccount> => {
  const client = lsrestclient.client();
  const params = {
    account_id,
  };
  const body = {
    password_change,
  };
  return await client({
    method: 'put',
    url: '/am/account/{account_id}/change_password',
    params,
    body,
  });
};

export type TAmAuthLoginDataType =
  | {
      type: 'userName';
      orgId: string;
      userName: string;
      password: string;
    }
  | {
      type: 'rfid';
      orgId: string;
      rfid: string;
    }
  | {
      type: 'apiKey';
      orgId: string;
      apiKey: string;
    };

type AmAuthLoginBodyType = {
  ORG_ID: string;
  USERNAME?: string;
  PASSWORD?: string;
  RFID?: string;
  API_KEY?: string;
};

type TAmAuthLoginResultType = {
  accessToken: string;
  tokenType: string;
};

export const apiAmAuthLogin = async (
  amAuthLoginData: TAmAuthLoginDataType,
): Promise<TAmAuthLoginResultType> => {
  const client = lsrestclient.client();

  const body: AmAuthLoginBodyType = {
    ORG_ID: amAuthLoginData.orgId,
  };

  switch (amAuthLoginData.type) {
    case 'userName':
      body.USERNAME = amAuthLoginData.userName;
      body.PASSWORD = amAuthLoginData.password;
      break;
    case 'apiKey':
      body.API_KEY = amAuthLoginData.apiKey;
      break;
    case 'rfid':
      body.RFID = amAuthLoginData.rfid;
      break;
  }
  try {
    const result = await client({
      method: 'post',
      url: '/am/auth/login',
      body,
    });
    const { access_token, token_type } = result;

    return {
      accessToken: access_token,
      tokenType: token_type,
    };
  } catch (e) {
    if (e instanceof LsApiError) {
      e.payload = { method: amAuthLoginData.type };
    }
    throw e;
  }
};

export type TApiAmOrganizationsReadResponse = TPaginationResponse<
  TOrganization | TOrganizationBase
>;

export type TApiAmOrganizationsReadParameter =
  TLsRestClientCall<TPaginationParameter>;

// export const apiAmOrganizationsRead = async ({
//   ...params
// }: TApiAmOrganizationsReadParameter): Promise<TApiAmOrganizationsReadResponse> => {
//   const client = lsrestclient.client();
//   // await sleep(3000);
//
//   return await client(
//     withPaginationParameters(
//       {
//         method: 'put',
//         url: '/am/organizations',
//       },
//       params,
//     ),
//   );
// };

export type TApiAmOrganizationReadParameter = TLsRestClientCall<{
  org_id: string;
}>;

export const apiAmOrganizationRead = async ({
  org_id,
}: TApiAmOrganizationReadParameter): Promise<TOrganization> => {
  const client = lsrestclient.client();
  const params = {
    org_id,
  };
  // await sleep(5000);

  return await client({
    method: 'get',
    url: '/am/organization/{org_id}',
    params,
  });
};

type TApiAmOrganizationReadUpdateParameter = TLsRestClientCall<{
  org_id: string;
  organization: TOrganization;
}>;

export const apiAmOrganizationUpdate = async ({
  org_id,
  organization,
}: TLsRestClientCall<TApiAmOrganizationReadUpdateParameter>): Promise<TOrganization> => {
  const client = lsrestclient.client();
  const params = {
    org_id,
  };
  return await client({
    method: 'put',
    url: '/am/organization/{org_id}',
    params,
    body: organization,
  });
};

type TApiAmOrganizationDeleteParameter = {
  org_id: string;
};
export const apiAmOrganizationDelete = async ({
  org_id,
}: TLsRestClientCall<TApiAmOrganizationDeleteParameter>): Promise<null> => {
  const client = lsrestclient.client();
  const params = {
    org_id,
  };
  return await client({
    method: 'delete',
    url: '/am/organization/{org_id}',
    params,
  });
};

type TApiAmOrganizationPost = {
  organization: TOrganization;
};
export const apiAmOrganizationCreate = async ({
  organization,
}: TLsRestClientCall<TApiAmOrganizationPost>): Promise<TOrganization> => {
  const client = lsrestclient.client();
  return await client({
    method: 'post',
    url: '/am/organization',
    body: organization,
  });
};

// export const apiAmRolesRead = async ({
//   ...parameter
// }: TLsRestClientCall<TPaginationParameter>): Promise<TRole | TRoleBase> => {
//   const client = lsrestclient.client();
//   return await client(
//     withPaginationParameters(
//       {
//         method: 'put',
//         url: '/am/roles',
//       },
//       parameter,
//     ),
//   );
// };

type TApiAmRoleReadParameter = { name: string };
export const apiAmRoleRead = async ({
  name,
}: TLsRestClientCall<TApiAmRoleReadParameter>): Promise<TRole | null> => {
  const client = lsrestclient.client();
  const params = {
    name,
  };
  return await client({
    method: 'get',
    url: '/am/role/{name}',
    params,
  });
};

type TApiAmRoleUpdateParameter = {
  name: string;
  role: TRole;
};
export const apiAmRoleUpdate = async ({
  name,
  role,
}: TLsRestClientCall<TApiAmRoleUpdateParameter>): Promise<TRole> => {
  const client = lsrestclient.client();
  const params = {
    name,
  };
  return await client({
    method: 'put',
    url: '/am/role/{name}',
    params,
    body: role,
  });
};

type TApiAmRoleDelete = {
  name: string;
};
export const apiAmRoleDelete = async ({
  name,
}: TLsRestClientCall<TApiAmRoleDelete>): Promise<null> => {
  const client = lsrestclient.client();
  const params = {
    name,
  };
  return await client({
    method: 'delete',
    url: '/am/role/{name}',
    params,
  });
};

type TApiAmRolePost = {
  role: TRole;
};
export const apiAmRoleCreate = async ({
  role,
}: TLsRestClientCall<TApiAmRolePost>): Promise<TRole> => {
  const client = lsrestclient.client();
  return await client({
    method: 'post',
    url: '/am/role',
    body: role,
  });
};

type TApiAmRolesScopesReadParameter = TLsRestClientCall<{
  roles: string[];
}>;

export const apiAmRolesScopesRead = async ({
  roles,
  ...parameter
}: TLsRestClientCall<TApiAmRolesScopesReadParameter>): Promise<
  TDictionary<any>
> => {
  const client = lsrestclient.client();
  const { amAuthPublicKey } = getLsConfig();
  const params = {
    roles,
  };
  const r = await client({
    method: 'get',
    url: '/am/roles/scopes',
    // cache: 'force-cache',
    next: { revalidate: 300 },
    params,
    ...parameter,
  });
  if (verifySignedStringDict(r, amAuthPublicKey)) {
    return JSON.parse(r.data);
  } else {
    throw Error('apiAmRolesScopesRead signature check failed.');
  }
};

// export const apiAmScopesRead = async ({
//   ...params
// }: TLsRestClientCall<TPaginationParameter>): Promise<TPaginationResponse<
//   TScope | TScopeBase
// > | null> => {
//   const client = lsrestclient.client();
//
//   return await client(
//     withPaginationParameters(
//       {
//         method: 'put',
//         url: '/am/scopes',
//       },
//       params,
//     ),
//   );
// };

type TApiAmScopeReadParameter = {
  name: string;
};

export const apiAmScopeRead = async ({
  name,
}: TLsRestClientCall<TApiAmScopeReadParameter>): Promise<TScope | null> => {
  const client = lsrestclient.client();
  const params = { name };
  return await client({
    method: 'get',
    url: '/am/scope/{name}',
    params,
  });
};

type TApiAmScopeUpdateParameter = {
  name: string;
  scope: TScope;
};
export const apiAmScopeUpdate = async ({
  name,
  scope,
}: TLsRestClientCall<TApiAmScopeUpdateParameter>): Promise<TScope> => {
  const client = lsrestclient.client();
  const params = { name };
  return await client({
    method: 'put',
    url: '/am/scope/{name}',
    params,
    body: scope,
  });
};

type TApiAmScopeDelete = {
  name: string;
};

export const apiAmScopeDelete = async ({
  name,
}: TLsRestClientCall<TApiAmScopeDelete>): Promise<null> => {
  const client = lsrestclient.client();
  const params = {
    name,
  };

  return await client({
    method: 'delete',
    url: '/am/scope/{name}',
    params,
  });
};

type TApiAmScopeCreateParameter = {
  scope: TScope;
};

export const apiAmScopeCreate = async ({
  scope,
}: TLsRestClientCall<TApiAmScopeCreateParameter>): Promise<TScope> => {
  const client = lsrestclient.client();
  return await client({
    method: 'post',
    url: '/am/scope',
    body: scope,
  });
};
