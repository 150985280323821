import * as crypto from 'crypto';

export type TSignedStringDict = {
  data: string;
  signature: string;
};

export const verifySignedStringDict = (
  signedStringDict: TSignedStringDict,
  publicKey: string,
): boolean => {
  const encodedData = new TextEncoder().encode(signedStringDict.data);

  return crypto.verify(
    'rsa-sha256',
    encodedData,
    {
      key: Buffer.from(publicKey),
      padding: crypto.constants.RSA_PKCS1_PSS_PADDING,
    },
    Buffer.from(signedStringDict.signature, 'base64'),
  );
};
