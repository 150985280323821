import _ from 'lodash';
import lsrestclient, {
  TLsRestClientCall,
} from '@shared/legacy_compat/lsrestclient/index';
import { withPaginationParameters } from '@shared/components/LsPagination/functions';
import { TPaginationParameter } from '@shared/types/pagination';
import { extract_data_for_mui_pagination_view } from '@shared/functions/extract_data_for_mui_pagination_view';

type TErpPromotionsRead = TPaginationParameter & {
  active?: boolean;
  future?: boolean;
  filter_class?: string;
  ids_merge_mode?: string;
  options?: string;
  reset?: string;
};
export const erpPromotionsRead = async ({
  active,
  future,
  filter_class,
  ids_merge_mode,
  options,
  reset,
  ...parameter
}: TLsRestClientCall<TErpPromotionsRead>) => {
  const client = lsrestclient.client();
  const res = await client(
    withPaginationParameters(
      {
        method: 'put',
        url: '/promotions',
        params: {
          view: 'FULL',
        },
        body: {
          filter: {
            filter_class,
            ids_merge_mode,
            options,
            reset,
            active,
            future,
          },
        },
      },
      parameter,
    ),
  );
  return _.get(res, ['data'], null);
};
