import jwt from 'jsonwebtoken';
// import { getLsConfig } from '@shared/nextjs/lsconfig/getLsConfig';
import _ from 'lodash';
import { getCookie } from '@shared/nextjs/cookies';
import {
  AmTokenScopeEnum,
  TAmScopes,
  TAmToken,
  TAmUserInfo,
} from '@shared/types';
import { apiAmRolesScopesRead } from '@shared/api/am';
import { getLsConfig } from '@shared/legacy_compat/nextjs/lsconfig/getLsConfig';

export const verifyAmTokenValue = (
  token: string,
  am_auth_public_key: string,
  org_id: string,
): TAmToken | undefined => {
  if (_.isNil(token)) return undefined;
  try {
    const decoded_token = jwt.verify(token, am_auth_public_key) as TAmToken;
    // @ts-ignore
    if (decoded_token.scopesOrgId !== org_id) {
      throw Error('scopesOrgId not matching orgId');
    }
    return decoded_token;
  } catch (error) {
    // console.log('jwt verify ', error);
    console.error(error);
    return undefined;
  }
};

export const getAmTokenValue = (token: string): TAmToken | undefined => {
  const { amAuthPublicKey, orgId } = getLsConfig();
  return verifyAmTokenValue(token, amAuthPublicKey, orgId);
};

export const getMissingScopes = (
  scopes: TAmScopes,
  demandedScopes?: TAmScopes,
): TAmScopes => {
  //no scopes demanded? access!
  if (_.isNil(demandedScopes) || Object.keys(demandedScopes).length === 0)
    return {};

  // commented out in favour of having the admin role maintained correctly.
  // if (_.get(scopes, 'admin', '') === 'rwcd') return {};

  return Object.entries(demandedScopes).reduce(
    (d: TAmScopes, current: [string, AmTokenScopeEnum]): TAmScopes => {
      const [scope, mode]: [string, AmTokenScopeEnum] = current;
      const scopeNotMatch = !(scope in scopes);
      // scopeNotMatch: demanded scope is not in given scopes. right part checks if the demanded mode is in the fulfilled scope
      if (scopeNotMatch || !scopes[scope].startsWith(mode)) {
        d[scope] = mode;
      }
      return d;
    },
    {} as TAmScopes,
  );
};

export type TAmTokenData = {
  loggedIn: boolean;
  scopes: TAmScopes;
  missingScopes: TAmScopes;
  scopesMatched: boolean;
  userInfo?: TAmUserInfo;
  token?: string;
};

export const getAmTokenName = () => {
  // asc! dont merge this in venus!
  const { runtimeConfig } = getLsConfig();
  const cookiePrefix = _.get(runtimeConfig, 'NEXT_PUBLIC_COOKIE_PREFIX');
  return `${cookiePrefix}_am_auth_token`;
};

export const getAmToken = (
  defaultValue: string | undefined = undefined,
): string | undefined => {
  try {
    return getCookie(getAmTokenName(), defaultValue) as string;
  } catch {
    return undefined;
  }
};

export const mergeScopes = (...scopes: TAmScopes[]): TAmScopes => {
  // Initialize a new object to store the merged scopes
  const mergedScopes: TAmScopes = {};

  // Iterate over each array of scopes
  scopes.forEach((scopeSet: TAmScopes) => {
    // Iterate over each scope in the current array
    Object.entries(scopeSet).forEach(
      ([key, value]: [string, AmTokenScopeEnum]) => {
        // If this scope already exists in the mergedScopes object, only replace it if the new mode is longer
        if (mergedScopes[key]) {
          if (value.length > mergedScopes[key].length) {
            mergedScopes[key] = value;
          }
        } else {
          // If this scope doesn't exist in the mergedScopes object yet, simply add it
          mergedScopes[key] = value;
        }
      },
    );
  });

  // Return the merged scopes
  return mergedScopes;
};

// scopes:needed scopes e.g. to see some restricted stuff
export const getAmTokenData = async (
  scopes?: TAmScopes,
): Promise<TAmTokenData> => {
  const ret: TAmTokenData = {
    loggedIn: false,
    scopes: {},
    missingScopes: {},
    scopesMatched: false,
    userInfo: undefined,
    token: undefined,
  };

  ret.token = getAmToken();
  if (ret.token) {
    const decodedToken = getAmTokenValue(ret.token);
    if (decodedToken) {
      const rolesScopes = (ret.scopes = decodedToken.scopes);

      // check via included scopes
      const tokenScopes = _.get(decodedToken, 'scopes', {});
      ret.scopes = tokenScopes;
      ret.missingScopes = getMissingScopes(ret.scopes, scopes);
      ret.scopesMatched = Object.keys(ret.missingScopes).length === 0;

      if (!ret.scopesMatched) {
        // check roles scopes

        //todo: pass used roles explicitly to apiAmRolesScopesRead

        const roles = _.get(decodedToken, 'roles', []);
        const scopesByRole = await apiAmRolesScopesRead({ roles: [] });
        const rolesScopes = mergeScopes(
          ...roles.map((role) => scopesByRole[role]),
        );

        ret.scopes = mergeScopes(rolesScopes, tokenScopes);
        ret.missingScopes = getMissingScopes(ret.scopes, scopes);
        ret.scopesMatched = Object.keys(ret.missingScopes).length === 0;
      }
      ret.loggedIn = !_.isNil(decodedToken);
      ret.userInfo = _.pick(
        decodedToken,
        'accountId',
        'accountName',
        'accountOrgIds',
      ) as TAmUserInfo;
      // console.log(ret);
    }
  }
  return ret;
};

export const useAmToken = async (scopes?: TAmScopes): Promise<TAmTokenData> => {
  return await getAmTokenData(scopes);
};
